import { Fragment, h, JSX } from 'preact';
import type { Language } from '../../../types/player-api-types.ts';
import { usePlayerData } from '../hooks/usePlayerData.tsx';
import { CONTROL_BAR_HEIGHT, DEFAULT_ASPECT } from '../utilities/constants.ts';
import { BigPlayButton } from '../../media/players/vulcanV2Player/shared/ui_components/BigPlayButton.tsx';
import { ProgressiveThumbnail } from '../../shared/ProgressiveThumbnail.jsx';
import { getLanguage } from '../../shared/translations.js';
import { thumbnailAssets as getThumbnailAssets } from '../../../utilities/assets.js';
import {
  getDefaultBigPlayButtonBorderRadius,
  getDefaultControlBarDistance,
  getDefaultPlayerBorderRadius,
} from '../../../utilities/roundedPlayerDefaults.ts';

interface PreloadThumbnailProps {
  isSwatchEnabled: boolean;
  mediaId: string;
  playerWidth: number;
}

export const PreloadThumbnail = ({
  isSwatchEnabled,
  mediaId,
  playerWidth,
}: PreloadThumbnailProps): JSX.Element => {
  const { embedOptions, mediaData } = usePlayerData();
  const {
    bigPlayButtonBorderRadius,
    controlBarBorderRadius,
    controlsVisibleOnLoad,
    floatingControlBar,
    newRoundedIcons,
    noMixBlendMode,
    playerBorderRadius,
    playerColor,
    playerLanguage,
    roundedPlayer,
    thumbnailAltText,
    transparentLetterbox,
  } = embedOptions;
  const { aspectRatio, assets, duration, mediaType, name } = mediaData;
  const height = playerWidth / (aspectRatio ?? DEFAULT_ASPECT);

  const defaultControlBarDistance = getDefaultControlBarDistance({
    controlBarBorderRadius,
    floatingControlBar,
    roundedPlayer,
  });
  const calculatedControlBarDistance = controlsVisibleOnLoad
    ? CONTROL_BAR_HEIGHT / 2 + defaultControlBarDistance
    : defaultControlBarDistance;

  const defaultBigPlayButtonBorderRadius = getDefaultBigPlayButtonBorderRadius({
    bigPlayButtonBorderRadius,
    roundedPlayer,
  });

  const defaultPlayerBorderRadius = getDefaultPlayerBorderRadius({
    playerBorderRadius,
    roundedPlayer,
  });
  const backgroundColor =
    transparentLetterbox === true || defaultPlayerBorderRadius > 0 ? 'transparent' : undefined;

  const thumbnailAssets = getThumbnailAssets(assets, {});

  const calculatedPlayerLanguage =
    (getLanguage(playerLanguage) as Language | undefined) ?? (getLanguage('en-US') as Language);

  return (
    <Fragment>
      <ProgressiveThumbnail
        backgroundColor={backgroundColor}
        images={thumbnailAssets}
        isVisible={true}
        hashedId={mediaId}
        playerBorderRadius={defaultPlayerBorderRadius}
        swatchEnabled={isSwatchEnabled}
        uiHasRendered={false}
        thumbnailAltText={thumbnailAltText ?? ''}
        height={`${height}px`}
      />
      <BigPlayButton
        borderRadius={defaultBigPlayButtonBorderRadius}
        buttonTabIndex={0}
        color={playerColor}
        controlBarDistance={calculatedControlBarDistance}
        duration={duration ?? 0}
        hasNewRoundedIcons={newRoundedIcons} // TODO: Remove once rounded icons are fully released
        isLiveMedia={mediaType === 'LiveStream'}
        isVisible={true}
        noMixBlendMode={noMixBlendMode}
        playerLanguage={calculatedPlayerLanguage}
        scale={1}
        showBpbTime={false}
        videoName={name ?? ''}
      ></BigPlayButton>
    </Fragment>
  );
};
